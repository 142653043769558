import React, { useCallback, useEffect, useState } from "react"
import { Typography } from "@material-ui/core";
import _ from "lodash";
import { useStyles } from "./SearchResults.style"
import { TicketResult } from "./SearchResultDetails/TicketResult.index";
import { AccessHolderResult } from "./SearchResultDetails/AccessHolderResult.index";
import { CredentialResult } from "./SearchResultDetails/CredentialResult.index";
import { OnePassResult } from "./SearchResultDetails/OnePassResult.jsx";

const SearchResultCategories = {
  AccessHolders: 0,
  ContractCredentials: 1,
  Tickets: 2,
  OnePass: 3
};

export default function SearchResults({ callID, entityID, searchResults, searchTerm }) {

  const classes = useStyles();
  const [searchResultsCategorized, setSearchResultsCategorized] = useState({});

  const massageSearchResults = useCallback((results) => {
    const sortedResults = [ [], [], [] ];
    for (const category of results.categories) {
      if (!_.isNil(category.children) && category.children.length > 0) {
        sortedResults.splice(getSearchResultCategory(category.name), 1, category.children);
      }
    }
    setSearchResultsCategorized(sortedResults);
  }, [setSearchResultsCategorized]);

  const getSearchResultCategory = (name) => {
    if (!name) return -1;
    let input = name.toLowerCase();
    switch (input) {
      case "access holders": 
        return SearchResultCategories.AccessHolders;
      case "credentials": 
        return SearchResultCategories.ContractCredentials;
      case "tickets": 
        return SearchResultCategories.Tickets;
      case "onepass":
        return SearchResultCategories.OnePass;
    }
  };

  const renderAccessHolders = () => {
    const results = searchResultsCategorized[SearchResultCategories.AccessHolders];
    if (!results || results.length === 0) {
      return <></>;
    }

    const accessHolders = [];
    for (const accessHolder of results) {
      accessHolders.push(
        <AccessHolderResult callID={callID} entityID={entityID} accessHolder={accessHolder} />
      );
    }
    return (
      <div>
        <Typography variant="h6" className={classes.sectionHeader}>Access Holders</Typography>
        {accessHolders}
      </div>
    );
  }

  const renderCredentials = () => {
    const results = searchResultsCategorized[SearchResultCategories.ContractCredentials];
    if (!results || results.length === 0) {
      return <></>
    }

    const credentials = [];
    for (const credential of results) {
      credentials.push(
        <CredentialResult callID={callID} entityID={entityID} credential={credential}/>
      );
    }

    return (
      <div>
        <Typography variant="h6" className={classes.sectionHeader}>Credentials</Typography>
        {credentials}
      </div>
    );
  }

  const renderTickets = () => {
    const results = searchResultsCategorized[SearchResultCategories.Tickets];
    if (!results || results.length === 0) {
      return <></>;
    }
    
    const tickets = [];
    for (const ticket of results) {
      tickets.push(
        <TicketResult callID={callID} entityID={entityID} ticket={ticket} searchTerm={searchTerm}/>
      );
    }

    return (
      <div>
        <Typography variant="h6" className={classes.sectionHeader}>Tickets</Typography>
        {tickets}
      </div>
    );
  }

  const renderOnePass = () => {
    const results = searchResultsCategorized[SearchResultCategories.OnePass];
    if (!results || results.length === 0) {
      return <></>;
    }
    
    const onePasses = [];
    for (const onePass of results) {
      onePasses.push(
        <OnePassResult callID={callID} entityID={entityID} ticket={onePass} searchTerm={searchTerm}/>
      );
    }

    return (
      <div>
        <Typography variant="h6" className={classes.sectionHeader}>ONE Pass</Typography>
        {onePasses}
      </div>
    );
  }

  useEffect(() => {
    massageSearchResults(searchResults);
  }, [massageSearchResults, searchResults])

  return(
    <div className={classes.resultsContainer}>
      {renderAccessHolders()}
      {renderCredentials()}
      {renderTickets()}
      {renderOnePass()}
    </div>
  )
}