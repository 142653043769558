import { lighten, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({

  searchingContainer: {
    height: "2.25em",
    width: "96%",
    paddingLeft: "5px",
    paddingTop: "1em"
  },

  credentialRow: {
    display: "flex",
    alignItems: "center",
    padding: "0.5em",
    margin: "0.25em 0"
  },

  clickableRow: {
    cursor: "pointer",
    "&:hover": {
      boxShadow: `0.5px 0.5px 0.5px 0.5px ${theme.palette.action.disabledBackground}`,
      backgroundColor: theme.palette.type === "light" 
        ? lighten(theme.palette.primary.light,.8) 
        : lighten(theme.palette.primary.light,.1),
      color: theme.palette.type === "light" 
        ? "inherit"
        : theme.palette.grey[900]
    },
    "&:focus": {
      boxShadow: `0.5px 0.5px 0.5px 0.5px ${theme.palette.action.disabledBackground}`,
      backgroundColor: theme.palette.type === "light" 
        ? lighten(theme.palette.primary.light,.8) 
        : lighten(theme.palette.primary.light,.1),
      color: theme.palette.type === "light" 
        ? "inherit"
        : theme.palette.grey[900]
    },
    transition: theme.transitions.create("box-shadow", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeIn,
    }),
  },

  credentialGrid: {
    display: "flex",
    alignItems: "center",
  },

  credentialReference: {
    color: theme.palette.grey[600]
  },

  searchHighlight: {
    backgroundColor: "yellow",
    color: theme.palette.grey[900]
  },

  credentialChip: {
    alignContent: "right"
  },

  expandedDetailsContainer: {
    margin: "auto",
    padding: "1em",
    border: `solid 1px ${theme.palette.grey.main}`,
    borderRadius: "5px",
    width: "96%" /* avoids horizontal scrollbar */
  },

  expandedDetailsContainerHeader: {
    display: "flex",
    textTransform: "uppercase",
    marginBottom: "0.5em"
  },

  statusSelector: {
    minWidth: "110px",
    marginLeft: "auto"
  },

  timeLabel: {
    marginBottom: "0.5em"
  },

  footerDiv: {
    marginTop: "0.5em",
    display: "flex"
  },

  sendCredentialsBtn: {
    marginLeft: "auto"
  },

  collapseTicket:{
    color: theme.palette.text.secondary,
    fontSize:"smaller",
  },

  onePassList: {
    justifyContent: "center",
  }

}));
