import React, { useCallback, useState } from "react";
import { Box, Button, Grid, LinearProgress, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp } from "@fortawesome/pro-duotone-svg-icons";

import _ from "lodash";
import clsx from "clsx";
import moment from "moment";

import { useStyles } from "./TicketResult.style";
import TicketService from "../../../../services/TicketService";
import apiClient from "../../../../auth/apiClient";
import SquareChip from "../../../SquareChip";
import CredentialStatusChange from "../../../Credentials/CredentialStatusChange";
import useHasPermissions from "../../../../hooks/useHasPermissions";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";
import useCurrentFacilityTimezone from "../../../../hooks/useCurrentFacilityTimezone";
import SendCredentialButton from "../../Modules/Buttons/SendCredentialButton";

const ticketService = new TicketService(apiClient);

const statusOptions = [
  { id: "out", name: "OUT" },
  { id: "in", name: "IN" },
  { id: "neutral", name: "NEUTRAL" },
];

const onepassStatus = {
  1: "IN",
  2: "OUT",
  3: "NEUTRAL"
}

export const OnePassResult = ({
  entityID,
  callID,
  ticket
}) => {
  
  const classes = useStyles();
  const [ticketData, setTicketData] = useState(ticket);
  // TODO: let parent control expansion? Only allow one open at a time?
  // Works as is, each row can be expanded and closed independently
  const [detailExpanded, setDetailExpanded] = useState(false);

  const EditTicketsPermission = useHasPermissions(["inventory.edit"]);
  const enqueueSnackbar = useEnqueueSnackbar();

  // TODO: should we use timezone of the device?
  // would change existing expectations on call center tickets search, however
  const { convertUtcDate } = useCurrentFacilityTimezone();

  const getFormattedTime = useCallback((date) => {
    return convertUtcDate(date).format("llll");
  }, [convertUtcDate]);

  const openDetail = useCallback(() => {
    setDetailExpanded(true);
  }, []);

  const closeDetail = () => {
    setDetailExpanded(false);
  }

  const handleKeyDown = useCallback((event) => {
    if (!detailExpanded && (event.key === "Enter" || event.key === " ")) {
      event.preventDefault();
      openDetail();
    }
  }, [detailExpanded, openDetail])

  const handleStatusSubmit = useCallback(async ({ status, reason }) => {
    ticketData.activityDate = moment().format(
      "YYYY-MM-DDTHH:mm:ssZ"
    );
    if (status?.toLowerCase() === "void") {
      ticketData.expiredDate = ticketData.activityDate;
    }
    ticketData.status = status;
    ticketData.statusChangeReason = reason;

    await handleUpdateTicket(ticketData);
  }, [ticketData]);

  const handleUpdateTicket = async ticket => {
    let response;
    try {
      response = await ticketService.updateTicket(ticket);
    } catch (err) {
      if (
        err.response?.status === 400 &&
        typeof err.response?.data === "string"
      ) {
        enqueueSnackbar(err.response.data, { variant: "warning" });
        return;
      } else {
        enqueueSnackbar("Failed to update status of ticket", {
          variant: "error",
          tag: "FailedToUpdateTicketStatus"
        });
        return;
      }
    }

    setTicketData(response.data);
    enqueueSnackbar("Ticket successfully updated", { variant: "success" });
  };

  return detailExpanded ? 
    _.isNil(ticketData)
      ? <Box className={classes.searchingContainer}>
          <LinearProgress />
        </Box>
      : (
        <div className={classes.expandedDetailsContainer}>
          <div className={classes.expandedDetailsContainerHeader}>
            <Typography data-id="ticket-id" variant="h6" color="primary">
              {ticketData.ticketid}
            </Typography>
            <CredentialStatusChange
              data-id="ticket-status-split-btn"
              disabled={!EditTicketsPermission}
              className={classes.statusSelector}
              statusOptions={statusOptions}
              onSubmit={handleStatusSubmit}
              defaultSelected={
                ticketData.transactionstate === 1
                  ? "In"
                  : ticketData.transactionstate === 2
                  ? "Out"
                  : "Neutral"
              }
            />
          </div>
          <Grid container className={classes.datesContainer} spacing={6}>
          <Grid item xs={12} md={6}>
              <Typography
                name="room number"
                color="textSecondary"
                className={classes.timeLabel}>
                Room Number
              </Typography>
              <Typography
                data-id="room-number"
                variant="h6"
                name="number">
                {ticketData?.roomnumber}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                name="onepass name"
                color="textSecondary"
                className={classes.timeLabel}>
                Name
              </Typography>
              <Typography
                data-id="onepass=name"
                variant="h6"
                name="name">
                {ticketData?.name}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                name="start time"
                color="textSecondary"
                className={classes.timeLabel}>
                Start Time
              </Typography>
              <Typography
                data-id="issued-date"
                variant="h6"
                name="issued date">
                {getFormattedTime(ticketData.validfrom)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                name="end time"
                color="textSecondary"
                className={classes.timeLabel}>
                End Time
              </Typography>
              <Typography
                data-id="end-date"
                variant="h6"
                name="end date">
                {getFormattedTime(ticketData.validto)}
              </Typography>
            </Grid>
          </Grid>
          <div className={classes.footerDiv}>
            <Button 
              startIcon={<FontAwesomeIcon icon={faCaretUp}/>}
              className={clsx(classes.collapseTicket, "collapse")}
              onClick={closeDetail}>
                Collapse
            </Button>
            <SendCredentialButton
              className={classes.sendCredentialsBtn}
               callID={callID}
               credential={ticketData}
               deviceID={entityID}
               variant="contained"
               color="primary" 
            />
          </div>
        </div>
  ) : (
    <div tabIndex={0}
      className={clsx(classes.credentialRow, classes.clickableRow)}
      onKeyDown={handleKeyDown}
      onClick={openDetail}
      data-credential={ticket.credentialreference}
      >
      <Grid container alignItems="center" spacing={1} className={clsx("onepass-list", classes.onePassList)}>
        <Grid item xs={3}>
          {ticket?.ticketid?.slice(ticket.ticketid?.length - 12)}
        </Grid>
        <Grid container item xs={4} className={classes.onePassList}>
          {ticket?.name}
        </Grid>
        <Grid container item xs={3} className={classes.onePassList}>
          {ticket?.roomnumber}
        </Grid>
        <Grid item xs={2}>
          <SquareChip uppercase
            mode={"unselected"}
            text={onepassStatus[ticket.transactionstate]}
          />
        </Grid>
      </Grid>
    </div>
  );
};